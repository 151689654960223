import { Controller } from "stimulus"
// Depends on https://github.com/ganlanyuan/tiny-slider
import { tns } from "tiny-slider/src/tiny-slider"

export default class extends Controller {
  static targets = ['canvas'];

  sliderObject;


  connect() {

    this.sliderObject = tns({
      container: this.canvasTarget,
      items: 1,
      center: true,
      slideBy: 1,
      arrowKeys: true,
      controls: false,
      nav: true,
      navPosition: 'bottom',
      loop: false,
      rewind: true,
      lazyload: false,
      swipeAngle: 100,
      mouseDrag: false,
      responsive: {
       0: { edgePadding: 30, gutter: 5 },
       700: { edgePadding: 0, gutter: 1 }
      }
    });

    // bind to index change event
    //this.sliderObject.events.on('indexChanged',this.onChange.bind(this));

  }

  navigate( e ) {
    //if( window.innerWidth < 700 ) return;
    var p = this.element.querySelector('.tns-outer').getBoundingClientRect();
    var middleX = p.left + (p.width/2);

    if( e.clientY > p.top && e.clientY < p.bottom ) {
      // left side click
      if( e.clientX >= p.left && e.clientX < middleX) {
        this.sliderObject.goTo('prev');
        // right side click
      } else if( e.clientX >= middleX && e.clientX < p.right) {
        this.sliderObject.goTo('next');
      }
    }
  }

  goTo( e ) {
    var index = e.target.dataset.index;
    this.sliderObject.goTo(index);
  }

  next() {
    this.sliderObject.goTo('next');
  }

  prev() {
    this.sliderObject.goTo('prev');
  }

  onChange(info) {
    var index = info.index -1;

  }

  disconnect() {
    this.sliderObject.destroy();
    this.sliderObject = null;
  }

}
