import { Controller } from 'stimulus'

/**
 * Displays the logo in the navbar if the header is out of view
 */

export default class extends Controller {
  static targets = ['navbar', 'toggleBackground'];

  connect() {
    if( this.hasToggleBackgroundTarget ) {
      let observer = new IntersectionObserver( (entries, observer) => {
        entries.forEach( entry => {
          //console.log(entry.intersectionRatio);

          if (entry.intersectionRatio > 0) { //visible
            this.navbarTarget.classList.remove('show--logo');
          } else {
            this.navbarTarget.classList.add('show--logo');
          }
        });
      }, {
        rootMargin: '-16px',
        threshold: [0, 1.0]
      });
      observer.observe(this.toggleBackgroundTarget);
    }
  }

  disconnect() {

  }
}