import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['button', 'menu', 'backdrop'];

  connect() {
  }

  toggle() {
    this.menuTarget.classList.toggle('open');
    this.element.classList.toggle('noscroll');
  }

  close() {
    this.menuTarget.classList.remove('open');
    this.element.classList.remove('noscroll');
  }
}