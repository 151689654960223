import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['fade'];


  connect() {
    if ('IntersectionObserver' in window) { //if supported
      this.initFadeObserver();
    }
    // TODO: Child Controller isn't initialised yet
    // if( window.location.hash ) { // if page loads at anchor position
    //   const anchor = document.querySelector( window.location.hash );
    //   if( anchor ) openChildAccordions( anchor );
    // }
  }

  scrollTo(selector) {
    if(selector.currentTarget) { // Triggered by click listener
      let event = selector;
      selector = event.currentTarget.dataset.anchor;
      if(window.location.pathname == "/")
        event.preventDefault(); // Prevent page refresh
    }
    const anchor = document.querySelector( selector );
    if( anchor ) {
      this.getNavMenuController().close(); // close menu
      anchor.scrollIntoView({ behavior: 'smooth' });
      this.openChildAccordions( anchor.parentNode );
    }
    // Add Anchor to URL without refresh
    window.history.pushState("", selector, selector);
  }


  openChildAccordions( parent ) {
    let accordions = parent.getElementsByClassName('accordion');
    for( var i=0; i<accordions.length; i++ ) {
      let accordion = this.application.getControllerForElementAndIdentifier(accordions[i], 'accordion');
      accordion.open();
    }
  }

  getNavMenuController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "navmenu")
  }

  /**
   * scroll linked fade animations
   */
  initFadeObserver() {
    if( this.hasFadeTarget ) {

      let observer = new IntersectionObserver( (entries, observer) => {
        entries.forEach( entry => {
          if (entry.intersectionRatio > 0.1) { //visible
            entry.target.classList.remove('fade--out');
            observer.unobserve( entry.target );
          }
        });
      }, {
        rootMargin: '0px 0px 0px -100px',
        threshold: [0, 0.12]
      });

      this.fadeTargets.forEach( section => {
        observer.observe(section);
        section.classList.add('fade');
        section.classList.add('fade--out');
      });
    }
  }

}