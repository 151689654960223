import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['content'];

  expanded;
  resizeListener;

  connect() {

  }

  toggle() {
    if( !this.expanded ) { //expand
      let height = this.contentTarget.scrollHeight;
      this.contentTarget.style.maxHeight = height+20+"px"
      // monitor window size
      this.resizeListener = this.recalculateHeight.bind(this);
      window.addEventListener('resize', this.resizeListener, false);
    } else { // minify
      window.removeEventListener('resize', this.resizeListener);
      this.contentTarget.style.maxHeight = 0
      let height = this.contentTarget.scrollHeight;
      window.scrollBy({ top: -height, left: 0, behavior: 'smooth' });
    }
    this.expanded = !this.expanded
    this.element.classList.toggle('expanded');
  }

  open() {
    if( !this.expanded ) {
      this.toggle();
    }
  }

  recalculateHeight() {
    let height = this.contentTarget.scrollHeight;
    this.contentTarget.style.maxHeight = height+20+"px"
  }

  disconnect() {
    window.removeEventListener('resize', this.resizeListener);
  }


}