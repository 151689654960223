import { Controller } from 'stimulus'

/**
 * Displays the sidebar when the header is out of view
 */

export default class extends Controller {
  static targets = ['sidebar', 'header'];

  connect() {
    if ('IntersectionObserver' in window) { //if supported
      this.initFadeObserver();
    }
  }

  initFadeObserver() {
    if( this.hasHeaderTarget && this.hasSidebarTarget ) {
      let observer = new IntersectionObserver( (entries, observer) => {
        entries.forEach( entry => {

          if (entry.intersectionRatio > 0.1) { //visible
            this.sidebarTarget.classList.add('hidden');
          } else {
            this.sidebarTarget.classList.remove('hidden');
          }
        });
      }, {
        rootMargin: '-150px',
        threshold: [0, 0.12]
      });
      observer.observe(this.headerTarget);
      this.sidebarTarget.classList.add('hidden');
    }
  }

  disconnect() {

  }
}